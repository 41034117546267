import { CatalogueItem } from "./types";
import { ValueOf } from "lib/types";
import {
  ApiResponseData,
  FlatCatalogue,
  instance,
  uploadImageToS3,
  camelToCapital,
} from "@clearabee/ui-sdk";
import { JOB_STATUS_MAPPING } from "./constants/jobStatusMapping";
import { FieldError } from "react-hook-form";
import dayjs from "dayjs";

export interface FormError {
  [key: string]: FormError | FieldError | undefined | FormError[];
}

export type JobStatusFilter =
  | ValueOf<typeof JOB_STATUS_MAPPING>
  | "All"
  | "AllActive";

export const extractPossibleNumber = (
  value: string | number | boolean | undefined,
): number | undefined => {
  return typeof value === "number" && !isNaN(value)
    ? value
    : typeof value === "string" && !isNaN(Number(value))
    ? Number(value)
    : undefined;
};

export const convertApiStatus = (status: string): string | undefined => {
  return Object.entries(JOB_STATUS_MAPPING).find(
    ([key]) => key === status,
  )?.[1];
};

export const requirePostcodePrice = (items: CatalogueItem[]): boolean => {
  return items.some(
    ({ pricingModel }) =>
      pricingModel === "postcode" || pricingModel === "suppliers",
  );
};

export const hideFromPrices = (categorySku: string): boolean => {
  const getPriceServices = ["SKIPS"];
  return getPriceServices.includes(categorySku);
};

export const convertUiStatus = (status: string): string | undefined => {
  return Object.entries(JOB_STATUS_MAPPING).find(
    ([, value]) => value === status,
  )?.[0];
};

export const postcodeRegex = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i;

export const formatPostcode = (postcode: string) => {
  const parts = postcode
    .replace(/\s/g, "")
    .match(/^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/i);

  if (!parts) {
    return postcode;
  }

  parts.shift();
  return parts.join(" ").toUpperCase();
};

export const getTopCategoryFromItems = (
  catalogue: FlatCatalogue,
  items: Record<"sku", string>[],
): CatalogueItem | undefined => {
  const item = getCategoryFromItems(catalogue, items);

  if (!item) {
    return undefined;
  }

  return getTopCategoryFromItems(catalogue, [item]) ?? item;
};

export const getCategoryFromItems = (
  catalogue: FlatCatalogue,
  items: Record<"sku", string>[],
): CatalogueItem | undefined => {
  for (const item of items) {
    const parentSku = catalogue?.items.find(
      ({ sku }) => sku === item.sku,
    )?.parentSku;

    if (parentSku && parentSku !== "SURCHARGES") {
      return catalogue.items.find(({ sku }) => parentSku === sku);
    }
  }

  return undefined;
};

export const handleImageUpload = async (
  basketToken: string,
  image: File,
): Promise<void> => {
  const {
    data: { url, fields },
  } = await instance.catalogues.putBasketUpload(
    basketToken,
    image.name,
    undefined,
    {
      params: {
        type: "photo",
      },
    },
  );

  await uploadImageToS3(image, url, fields);
};

export const debounce = <Props, Callback = (...props: Props[]) => void>(
  callback: Callback,
  wait = 10,
): ((...args: Props[]) => void) => {
  let timerId: number;
  return (...args: Props[]) => {
    window.clearTimeout(timerId);
    timerId = window.setTimeout(() => {
      (callback as unknown as (...props: Props[]) => void)(...args);
    }, wait);
  };
};

export const sortByPrice = (items: CatalogueItem[]): CatalogueItem[] => {
  return items.sort((a, b) => (a.price ?? 0) - (b.price ?? 0));
};

export const isDatePanelShown = (
  categorySku: string,
  productSku?: string,
): boolean => {
  const hiddenServices = ["BAGONLYSKIPBAGS", "BAGCOLLECTIONSKIPBAGS"];
  return !hiddenServices.includes(productSku ?? categorySku);
};

export const capitalise = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.substring(1);
};

export const isPrepaidProduct = (sku: string): boolean =>
  sku === "PREPAIDCOLLECTIONSKIPBAGS";

export const showDeliveryText = (categorySku: string | undefined): boolean =>
  !!categorySku &&
  ["SKIPS", "BAGONLYSKIPBAGS", "BAGCOLLECTIONSKIPBAGS"].includes(categorySku);

export const belongsToSkipBags = (itemSku: string): boolean =>
  [
    "BAGONLYSKIPBAGS",
    "BAGCOLLECTIONSKIPBAGS",
    "COLLECTIONONLYSKIPBAGS",
    "PREPAIDCOLLECTIONSKIPBAGS",
  ].includes(itemSku);

export const getBulkCategory = (sku: string): string | undefined => {
  if (belongsToSkipBags(sku)) return "SKIPBAGS";
  if (["HOUSECLEARANCE", "BINCOL"].includes(sku)) return sku;
  return undefined;
};

export const buildErrorsList = (
  errors: FormError,
  mapping: Record<string, string> = {},
) => {
  for (const [key, value] of Object.entries(errors)) {
    if (!Array.isArray(value) && value?.message && value?.type) {
      mapping[camelToCapital(key)] = value.message as string;
    } else {
      buildErrorsList(value as FormError, mapping);
    }
  }
  return mapping;
};

export const getBasketInitialValues = (
  basket: ApiResponseData<typeof instance.catalogues.getBasket>,
  itemIndex: number,
  hasProhibitedItems: boolean,
  surcharges: { sku: string }[],
  isBulkAdd: boolean,
  products?: CatalogueItem[],
) => {
  return {
    ...basket,
    date: basket.date ? dayjs(basket.date).format("YYYY-MM-DD") : undefined,
    contact: basket.contact || undefined,
    deliveryAddress: basket.deliveryAddress || undefined,
    prohibitedItems: hasProhibitedItems ? false : undefined,
    collectionDate: basket.meta.collectionDate
      ? dayjs(basket.meta.collectionDate).format("YYYY-MM-DD")
      : undefined,
    deliveryCharge: basket.deliveryCharge || undefined,
    quantity: basket.items[itemIndex].qty,
    delivery: {
      deliveryOption: basket.deliveryOption || undefined,
      timeslot: basket.timeslot
        ? `${basket.timeslot?.from.replace(
            "00:00:00",
            "00:00",
          )}-${basket.timeslot?.to.replace("00:00:00", "00:00")}`
        : undefined,
    },
    extras:
      surcharges?.reduce<Record<string, number>>((surcharges, surcharge) => {
        return {
          ...surcharges,
          [surcharge.sku]:
            basket.items.find(({ sku }) => surcharge.sku === sku)?.qty || 0,
        };
      }, {}) ?? {},
    ...(isBulkAdd
      ? {
          products: products?.reduce<Record<string, number>>(
            (final, current) => {
              return {
                ...final,
                [current.sku]:
                  basket.items.find(({ sku }) => current.sku === sku)?.qty || 0,
              };
            },
            {},
          ),
        }
      : {}),
  };
};

export const toTitleCase = (input: string): string => {
  return input.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase(),
  );
};
